import React, { useState, useRef, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import main from "src/assets/styles/main.js";
import typographySizes from "src/assets/styles/typographySizes.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";
import distances from "src/assets/styles/distances.js";
import ContextConsumer from "src/layouts/Context.js";

const All = styled.div`
  @media (max-width: ${mediaQuery.tablet}) {
    &.hide {
      display: none;
    }
  }
`;

const Cover = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  background-color: black;
  opacity: 0;
  height: 100vh;
  width: 100vw;
  z-index: 999998;
  pointer-events: none;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  &.open {
    opacity: 0.8;
    pointer-events: all;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  @media (max-width: ${mediaQuery.tablet}) {
    z-index: 997;
  }
`;
const ButtonTopWrapper = styled.button`
  background-color: var(--rest);
  /* border: solid 1rem var(--bg); */
  font-size: ${typographySizes.s}rem;
  text-align: center;
  color: var(--bg);
  align-self: center;
  cursor: pointer;
  /* margin-left: 50rem; */
  margin-top: ${distances.gap}rem;
  border-radius: 60rem;
  height: 50rem;
  overflow: hidden;
  line-height: 50rem;
  z-index: 9999999;
  padding: 0 20rem;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 14rem;

  span {
    font-size: inherit;
    color: inherit;
    display: block;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  :hover {
    span {
      transform: translateY(-100%);
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }

  @media (max-width: ${mediaQuery.tablet}) {
    top: initial;
    border-radius: 7rem;
    bottom: 15rem;
    left: 15rem;
    transform: translateX(0%);
    width: calc(-137rem + 100vw);
    /* display: none; */
    z-index: 999;
  }
`;
const Wrapper = styled.div`
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  top: 115rem;
  z-index: 999999;
  max-height: 0;
  overflow: scroll;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  border-radius: ${main.radius}rem;
  &.open {
    max-height: calc(100vh - 165rem);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  @media (max-width: ${mediaQuery.tablet}) {
    top: 20rem;
    &.open {
      max-height: calc(100vh - 105rem);
    }
  }
`;

const FormWrapper = styled.div`
  background-color: ${(props) =>
    props.colorBg ? props.colorBg : "var(--rest)"};
  padding: 20rem;
  width: 680rem;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100vw - 70rem);
  }
`;

const Title = styled.h3`
  font-size: ${typographySizes.m}rem;
  line-height: ${typographySizes.mh};
  &.confirmation {
    padding: 50rem 50rem;
    text-align: center;
    @media (max-width: ${mediaQuery.tablet}) {
      padding: 50rem 0;
      font-size: ${typographySizes.sm}rem;
    }
  }

  /* margin-bottom: 40rem; */
`;

const OrderForm = styled.form`
  width: 100%;
  flex: 1;
  display: flex;
  margin-top: 40rem;
  justify-content: space-between;
  flex-wrap: wrap;
`;
const StyledInput = styled.input`
  all: unset;
  display: inline;
  width: calc(50% - 10rem);
  padding: 10rem 0;
  margin-bottom: ${distances.gap}rem;
  outline: none;
  color: var(--bg);
  font-size: ${typographySizes.s}rem !important;
  border-radius: 0;
  -webkit-appearance: none;
  background-color: transparent;
  border-bottom: solid var(--bg) 1rem;

  @media screen {
  }
  &.email {
    width: 100%;
    /* margin-bottom: 20rem; */
  }
  ::placeholder {
    all: unset;
    color: var(--bg);
  }
  ::autofill {
    background-color: transparent;
  }
  :valid {
    background-color: transparent;
  }
`;

const CheckboxCategory = styled.div`
  margin-bottom: 10rem;
`;

const CategoryTitle = styled.h4`
  font-size: ${typographySizes.ss}rem;
  width: 100%;
  margin-bottom: 5rem;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledCheckmark = styled.span`
  display: block;
  position: relative;

  vertical-align: middle;
  padding: 15rem 25rem;
  cursor: pointer;
  border: solid 1rem var(--bg);
  background-color: var(--rest);
  border-radius: ${main.radius}rem;
  flex-shrink: 0;
  margin-right: 10px;
  margin-bottom: 10rem;
  &.disabled {
    opacity: 0.2;
  }
`;

const PersonaCheckmarkWrapper = styled.label`
  display: flex;

  font-size: ${typographySizes.ss}rem !important;
  color: var(--rest);
  margin-top: 0rem;
  position: relative;
  overflow: hidden;
  :first-of-type {
  }

  input {
    /* display: none; */
    top: 5rem;
    left: -40rem;
    position: absolute;
    border-radius: 0;
    /* -webkit-appearance: none; */
  }

  input:checked + ${StyledCheckmark} {
    border: solid 1rem var(--rest);
    background-color: var(--bg);
    color: var(--rest);
  }
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  height: 100rem;
  border: solid 1rem var(--bg);
  background-color: var(--rest);
  border-radius: ${main.radius}rem;
  font-size: ${typographySizes.s}rem !important;
  padding: 10rem;
  margin-bottom: 40rem;
`;
const ProductOrderBottomWrapper = styled.div``;
const ButtonWrapper = styled.button`
  border: solid 1rem var(--bg);
  font-size: ${typographySizes.m}rem;
  text-align: center;
  color: var(--bg);
  align-self: center;
  cursor: pointer;
  /* margin-left: 50rem; */
  margin-top: ${distances.gap}rem;
  border-radius: 60rem;
  height: 51rem;
  overflow: hidden;
  padding: 0 40rem;
  width: 100%;
  span {
    font-size: inherit;
    color: inherit;
    display: block;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  :hover {
    span {
      transform: translateY(-100%);
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }

  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.sm}rem;
    span {
      height: 51rem;
      line-height: 51rem;
    }
  }
`;
const StyledCheckmarkGdpr = styled.span`
  display: block;
  position: relative;
  width: 30rem;
  height: 30rem;
  vertical-align: middle;
  margin-right: 20rem;
  cursor: pointer;
  border: solid 1rem var(--bg);
  background-color: var(--rest);
  flex-shrink: 0;
  border-radius: ${main.radius}rem;
  ::before {
    content: "";
    position: absolute;
    width: 0%;
    left: 50%;
    top: 50%;
    height: 6rem;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: var(--bg);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  ::after {
    content: "";
    position: absolute;
    width: 0%;
    left: 50%;
    top: 50%;
    height: 6rem;
    transform: translate(-50%, -50%) rotate(-45deg);
    background-color: var(--bg);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;
const PersonaCheckmarkWrapperGdpr = styled.label`
  display: flex;
  margin-bottom: 20rem;
  font-size: ${typographySizes.ss}rem !important;
  position: relative;
  color: var(--bg);
  margin-top: 0rem;

  margin-top: 30rem;
  :first-of-type {
  }

  input {
    top: 5rem;
    /* display: none; */
    position: absolute;
    border-radius: 0;
    /* -webkit-appearance: none; */
  }

  input:checked + ${StyledCheckmarkGdpr} {
    ::after {
      width: 80%;
    }
    ::before {
      width: 80%;
    }
  }
`;

const ContactForm = ({ navOpen, colorBg, colorText, lang, hide }) => {
  const [openForm, setOpenForm] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [brandingChecked, setBrandingChecked] = useState(false);
  const [wwwChecked, setWwwChecked] = useState(false);
  const [selectedBudget, setSelectedBudget] = useState(""); // State to track selected budget

  const handleSubmit = (event) => {
    event.preventDefault();
    setConfirmation(true);
    const formData = new FormData(event.target);
    const formObject = {};

    formData.forEach((value, key) => {
      formObject[key] = value;
    });

    fetch("https://submit-form.com/Mzl8kusuP", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(formObject),
    })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  // Logic to disable the 10-20K PLN radio button
  const isBudgetDisabled = brandingChecked && wwwChecked;

  // useEffect to handle automatic budget change when both branding and www are selected
  useEffect(() => {
    if (brandingChecked && wwwChecked && selectedBudget === "10-20K PLN") {
      setSelectedBudget("20-50K PLN"); // Automatically switch to 20-50K PLN
    }
  }, [brandingChecked, wwwChecked, selectedBudget]);

  const buttonTopTextPl = openForm ? "zamknij" : "zaplanuj projekt";
  const buttonTopTextEn = openForm ? "close" : "plan the project";

  return (
    <ContextConsumer>
      {({ data, set }) => (
        <All className={hide && "hide"}>
          <Cover
            onClick={() => setOpenForm(!openForm)}
            className={openForm && "open"}
          />
          <ButtonTopWrapper onClick={() => setOpenForm(!openForm)}>
            <span>{lang === "pl" ? buttonTopTextPl : buttonTopTextEn}</span>
            <span>{lang === "pl" ? buttonTopTextPl : buttonTopTextEn}</span>
          </ButtonTopWrapper>
          <Wrapper className={openForm && !data.navOpen && "open"}>
            {confirmation ? (
              <FormWrapper>
                {" "}
                <Title className="confirmation">
                  {lang === "pl"
                    ? "Dziękujemy za wiadomość. Wkrótce się odezwiemy. 😉"
                    : "Thank you for the news. We will get back to you soon 😉"}
                </Title>
              </FormWrapper>
            ) : (
              <FormWrapper>
                <Title>
                  {lang === "pl"
                    ? "Masz wizję, która zmieni wszystko? Opowiedz nam o niej!"
                    : "Do you have a vision that will change everything? Tell us about it!"}
                </Title>
                <OrderForm onSubmit={handleSubmit}>
                  <StyledInput
                    type="text"
                    id="Name"
                    name="Name"
                    required
                    placeholder={lang === "pl" ? "imię" : "name"}
                  />
                  <StyledInput
                    type="text"
                    id="Company"
                    name="Company"
                    placeholder={lang === "pl" ? "nazwa firmy" : "company name"}
                  />
                  <StyledInput
                    type="text"
                    className="email"
                    id="email"
                    name="email"
                    required
                    placeholder="e-mail"
                  />
                  <CheckboxCategory>
                    <CategoryTitle>
                      {lang === "pl"
                        ? "co możemy dla ciebie zrobić? "
                        : "what can we do for you? "}
                    </CategoryTitle>
                    <CheckboxWrapper>
                      <PersonaCheckmarkWrapper>
                        <input
                          name="branding"
                          type="checkbox"
                          checked={brandingChecked}
                          onChange={(e) => setBrandingChecked(e.target.checked)}
                        />
                        <StyledCheckmark>
                          {lang === "pl" ? "branding" : "branding"}
                        </StyledCheckmark>
                      </PersonaCheckmarkWrapper>
                      <PersonaCheckmarkWrapper>
                        <input
                          name="www"
                          type="checkbox"
                          checked={wwwChecked}
                          onChange={(e) => setWwwChecked(e.target.checked)}
                        />
                        <StyledCheckmark>
                          {lang === "pl" ? "strona www" : "website"}
                        </StyledCheckmark>
                      </PersonaCheckmarkWrapper>
                      <PersonaCheckmarkWrapper>
                        <input name="other" type="checkbox" />
                        <StyledCheckmark>
                          {lang === "pl" ? "coś innego" : "other"}
                        </StyledCheckmark>
                      </PersonaCheckmarkWrapper>
                    </CheckboxWrapper>
                  </CheckboxCategory>
                  <CheckboxCategory>
                    <CategoryTitle>
                      {lang === "pl" ? "twój budżet" : "your budget"}
                    </CategoryTitle>
                    <CheckboxWrapper>
                      <PersonaCheckmarkWrapper>
                        <input
                          name="budget"
                          type="radio"
                          value="10-20K PLN"
                          disabled={isBudgetDisabled}
                          checked={selectedBudget === "10-20K PLN"} // Dynamically check based on user selection
                          onChange={(e) => setSelectedBudget(e.target.value)} // Update state on user selection
                        />
                        <StyledCheckmark
                          className={isBudgetDisabled && "disabled"}
                        >
                          10-20K PLN
                        </StyledCheckmark>
                      </PersonaCheckmarkWrapper>
                      <PersonaCheckmarkWrapper>
                        <input
                          name="budget"
                          type="radio"
                          value="20-50K PLN"
                          checked={selectedBudget === "20-50K PLN"}
                          onChange={(e) => setSelectedBudget(e.target.value)}
                        />
                        <StyledCheckmark>20-50K PLN</StyledCheckmark>
                      </PersonaCheckmarkWrapper>
                      <PersonaCheckmarkWrapper>
                        <input
                          name="budget"
                          type="radio"
                          value="50K+ PLN"
                          checked={selectedBudget === "50K+ PLN"}
                          onChange={(e) => setSelectedBudget(e.target.value)}
                        />
                        <StyledCheckmark>50K+ PLN</StyledCheckmark>
                      </PersonaCheckmarkWrapper>
                    </CheckboxWrapper>
                  </CheckboxCategory>
                  <CategoryTitle>
                    {lang === "pl"
                      ? "opisz swój projekt i jak możemy ci pomóc"
                      : "describe your project and how we can help you"}
                  </CategoryTitle>
                  <StyledTextarea
                    placeholder={
                      lang === "pl"
                        ? "opisz swój projekt"
                        : "describe your project"
                    }
                    id="description"
                    name="description"
                    cols="50"
                  />
                  <ProductOrderBottomWrapper>
                    <PersonaCheckmarkWrapperGdpr>
                      <input name="Gdpr" type="checkbox" required />
                      <StyledCheckmarkGdpr />
                      {lang === "pl"
                        ? "Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z ustawą o ochronie danych osobowych w związku z realizacją zgłoszenia. Podanie danych jest dobrowolne, ale niezbędne do przetworzenia zapytania. Zostałem /am poinformowany /a, że przysługuje mi prawo dostępu do swoich danych, możliwości ich poprawiania, żądania zaprzestania ich przetwarzania. Administratorem danych osobowych jest we3studio s.c. ul. Mokotowska 52a, m27, 00-543 Warszawa."
                        : `I consent to the processing of my personal data in accordance with the Law on Personal Data Protection in connection with the execution of the request. Provision of data is voluntary, but necessary to process the request. I have been informed that I have the right to access my data, the possibility of correcting them, demand to stop processing them. The administrator of the personal data is we3studio s.c. Mokotowska 52a street, m27, 00-543 Warsaw.`}
                    </PersonaCheckmarkWrapperGdpr>
                    <ButtonWrapper type="submit">
                      <span>
                        {lang === "pl" ? "wyślij wiadomość" : `send message `}
                      </span>
                      <span>
                        {lang === "pl" ? "wyślij wiadomość" : `send message `}
                      </span>
                    </ButtonWrapper>
                  </ProductOrderBottomWrapper>
                </OrderForm>
              </FormWrapper>
            )}
          </Wrapper>
        </All>
      )}
    </ContextConsumer>
  );
};

export default ContactForm;
